<template>
	<div class="download">
		<div class="banner">
			<img src="@/assets/download/HANDandPRODUCTSCN.png" alt="" class="HANDandPRODUCTS" v-if="lang=='zh'">
			<img src="@/assets/download/HANDandPRODUCTSEN.png" alt="" class="HANDandPRODUCTS" v-if="lang=='en'">
			<img src="@/assets/download/HANDandPRODUCTSVIE.png" alt="" class="HANDandPRODUCTS" v-if="lang=='vie'">
			<div class="logo">
				<img src="@/assets/download/DownloadCN.png" alt="" v-if="lang=='zh'">
				<img src="@/assets/download/DownloadEN.png" alt="" v-if="lang=='en'">
				<img src="@/assets/download/DownloadVT.png" alt="" v-if="lang=='vie'">
			</div>
		</div>
		<div class="inner">
			<div class="item">
				<div class="top">
					<img src="@/assets/download/QRCODE.png" alt="">
				</div>
				<div class="txt">
					{{i18n('328')}}
				</div>
				<div class="txt2">
					{{i18n('329')}} <span>
						野菜生鲜/Yeah Fresh
					</span>
				</div>
			</div>
			<div class="item">
				<div class="top">
					<img src="@/assets/download/WhatsAppImage.jpg" alt="">
				</div>
				<div class="txt">
					{{i18n('330')}}
				</div>
				<div class="txt2">
					{{i18n('331')}} <span>
						野菜生鲜
					</span>
				</div>
			</div>
		</div>




		<foot></foot>
	</div>
</template>

<script>
	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "download",
		components: {
			foot
		},
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		destroyed() {

		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped="scoped">
	.inner{
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 100px;
		display: flex;
		align-items: center;
	}
	.inner .item{
		width: 600px;
	}
	.inner .item .top{
		height: 430px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}
	.inner .item .top img{
		display: block;
	}
	.inner .item .txt,.inner .item .txt2{
		font-size: 18px;
		color: #000;
		text-align: center;
		font-weight: 600;
	}
	.inner .item .txt2 span{
		color: #189120;
	}
	.banner {
		width: 1920px;
		height: 509px;
		margin-bottom: 50px;
		background: url(../assets/download/Tiles.jpg);
		overflow: hidden;
		padding-top: 50px;
		box-sizing: border-box;
		position: relative;
	}

	.banner .logo {
		position: absolute;
		left: 0;
		top: 180px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.banner .logo img {
		display: block;
	}

	.HANDandPRODUCTS {
		width: 1440px;
		margin: 0 auto;
		display: block;
	}
</style>
